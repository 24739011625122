import React from 'react';
import PropTypes from 'prop-types';
import { Link, Button, List, ListItem } from '@lmig/lmds-react';
import JsxParser from '@lmig/dotcom-aspect-components/Utility/react-jsx-parser/components/JsxParser';
import Heading from '@lmig/dotcom-aspect-components/Utility/Heading';
import PhoneNumber from '@lmig/dotcom-aspect-components/Utility/PhoneNumber';
import TextBody from '@lmig/dotcom-aspect-components/Utility/TextBody';
import Hr from '@lmig/dotcom-aspect-components/Utility/Hr';
import Copyright from '@lmig/dotcom-aspect-components/Utility/Copyright';
import { Q, A } from '@lmig/dotcom-aspect-components/Utility/Faq';
import LmdsIcon from '@lmig/dotcom-aspect-components/Icon/LmdsIcons';
import LinkEnvironmentalize from './LinkEnvironmentalize';
import WrapperSizeable from './Utility/WrapperSizeable';

const RichText = ({
  jsx = [],
  renderInWrapper = false
}) => (
  <JsxParser
    jsx={jsx}
    renderInWrapper={renderInWrapper}
    components={{
      Heading,
      PhoneNumber,
      TextBody,
      Link,
      LinkEnvironmentalize,
      Button,
      Hr,
      Q,
      A,
      Copyright,
      LmdsIcon,
      List,
      ListItem,
      WrapperSizeable
    }}
  />
);

RichText.propTypes = {
  /* eslint-disable-next-line react/forbid-prop-types */
  jsx: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  renderInWrapper: PropTypes.bool,
};

export default RichText;