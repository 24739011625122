export function scoreAgent({ rating, reviewCount, completenessScore, completenessWeight = 0.2, expectation = 4.9, weight = 3 }) {
  // Magic number defaults:
  // completenessWeight is plenty impactful with a low value due to Math.log
  // expectation is undercut from an average agent rating of 4.96. playground.mongodb query:
  // db.agents_model_released.aggregate([
  // { $match: { reviewCount: { $gte: 5 } } },
  // { $group: { _id: null, avg_rating: { $avg: "$rating" } } }
  // ]);
  // weight is somewhat arbitrary. It has an inverse relation to probabilistic variance.
  if (reviewCount === 0 && weight === 0) {
    return completenessScore * completenessWeight;
  }

  const safe = {};
  safe.rating = rating || 0;
  safe.reviewCount = reviewCount || 0;
  safe.completenessScore = completenessScore || 0;
  safe.completenessWeight = completenessWeight >= 0 && completenessWeight <= 1 ? completenessWeight : 0.2;
  // Why this calculation? https://stackoverflow.com/a/50476254
  const adjustedRating = (safe.rating * safe.reviewCount + expectation * weight) / (weight + safe.reviewCount);
  const scoreFloat = safe.completenessScore * safe.completenessWeight + 20 * adjustedRating * (1 - safe.completenessWeight);

  return Math.round(1000 * scoreFloat) / 1000;
}

export function sortWeightedLottery(_agents, partitioned = false) {
  let agents = [];
  let agentsNoPhoto = [];

  if (partitioned) {
    agents = _agents;
  } else {
    _agents.forEach(agent => {
      if (agent.profile?.fields?.image) {
        agents.push(agent);
      } else {
        agentsNoPhoto.push(agent);
      }
    });
    agentsNoPhoto = sortWeightedLottery(agentsNoPhoto, true);
  }

  const tickets = agents.reduce((acc, agent) => {
    const score = scoreAgent({ rating: agent.rating, reviewCount: agent.reviewCount, completenessScore: agent.profile?.completeness });
    acc[agent.nNumber] = 5 - Math.log((100 - score) + 0.01);

    return acc;
  }, {});

  const orderedAgents = [];

  while (orderedAgents.length < agents.length) {
    const remainingAgents = agents.filter(agent => !orderedAgents.includes(agent));
    const totalTickets = remainingAgents.reduce((sum, agent) => sum + tickets[agent.nNumber], 0);
    const randomNum = Math.random() * totalTickets;
    let cumulativeTickets = 0;

    remainingAgents.every(agent => {
      cumulativeTickets += tickets[agent.nNumber];

      if (randomNum < cumulativeTickets) {
        orderedAgents.push(agent);

        return false;
      }

      return true;
    });
  }

  return orderedAgents.concat(agentsNoPhoto);
}